<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Basic Info</h4>
                        <div class="row mt-3">
                                <div class="col-md-3 ">
                                    <div class="d-inline-flex">
                                        <label >Name : </label>
                                        <div class="mr-3 align-self-center h6"> {{bikerData.name}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3 ">
                                    <div class="d-inline-flex">
                                        <label >Surname : </label>
                                        <div class="mr-3 align-self-center h6"> {{bikerData.surname}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="d-inline-flex">
                                        <label >Number : </label>
                                        <div class="mr-3 align-self-center h6"> {{bikerData.number}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="d-inline-flex">
                                        <label >Email : </label>
                                        <div class="mr-3 align-self-center h6"> {{bikerData.email}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-3">
                                    <div class="d-inline-flex">
                                        <label >Coverage Area : </label>
                                        <div class="mr-3 align-self-center h6"> {{bikerData.coverage_area}}</div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary mt-4 " @click.prevent="edit()">Edit</button>
                    </div>
                    <div class="table-responsive" style="margin-top: 5rem">
                            <b-table
                                    :items="bikerInfo"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" v-b-tooltip.hover  title="View Details"  variant="info" @click="view(item)">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover title="Edit" variant="success" @click="edit(item)" >
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" v-b-tooltip.hover  v-b-modal.modal-delete @click="choosenID(item)"  title="Delete" variant="danger">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                </div>
                
            </div>
        </div>
    </layout>


</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import {tableData} from "../site/dataAdminEID";
    import {axios_get} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "View Transport System",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,PageHeader },
        data() {
            return {
                title: "View Transport System",
                bikerData: null,
                bikerInfo: null,
                items: [
                    {
                        text: "Transports Systems",
                        href: "/bikers"
                    },
                    {
                        text: "View Transport System",
                        active: true
                    }
                ],
                tableData: tableData,
                siteData:null,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "site.site_name", sortable: true, label: "Site Name" },
                    { key: "site.site_category", sortable: true, label: "Site Category"  },
                    { key: "pick_up_site", sortable: true },
                    { key: "date_of_pickup", sortable: true },
                    { key: "year", sortable: true },
                    { key: "distance_travelled", sortable: true },
                ],
            };

        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            formSubmit(e) {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
            },

            tooltipForm() {
                this.submitform = true;
                this.$v.$touch();
            },

            /**
             * Range validation form submit
             */
            // eslint-disable-next-line no-unused-vars
            rangeform(e) {
                this.submit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            /**
             * Validation type submit
             */
            // eslint-disable-next-line no-unused-vars
            typeForm(e) {
                this.typesubmit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            edit(){
                window.location.href = '/biker/edit-biker/'+this.$route.params.id;
            }

        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id),
            axios_get("/bikers/"+this.id,{},(d)=>{
                this.bikerData=d
                console.log("success", d);
                axios_get("/bikers/enrollment/"+this.id,{},(da)=>{
                    this.bikerInfo=da.enrollments
                    console.log("success", this.bikerInfo);
                },(e)=>{
                    console.log("error", e);
                },()=>{
                    console.log("finishing");
                });
            },(e)=>{
                console.log("error", e);
            },()=>{
                console.log("finishing");
            });
        }
    };
</script>

<style scoped>

</style>